import assign from 'lodash/fp/assign';
import filter from 'lodash/fp/filter';
import find from 'lodash/fp/find';
import findIndex from 'lodash/fp/findIndex';
import first from 'lodash/fp/first';
import flatMap from 'lodash/fp/flatMap';
import flow from 'lodash/fp/flow';
import forEach from 'lodash/fp/forEach';
import get from 'lodash/fp/get';
import includes from 'lodash/fp/includes';
import intersection from 'lodash/fp/intersection';
import isEmpty from 'lodash/fp/isEmpty';
import map from 'lodash/fp/map';
import size from 'lodash/fp/size';
import split from 'lodash/fp/split';
import sum from 'lodash/fp/sum';

import { createSelector } from 'reselect';

import addDuplicateVariantToScan from 'utils/addDuplicateVariantsToScan';
import brushChecker from 'utils/brushChecker';
import packingDisplayedItems from 'utils/packingDisplayedItems';
import { productNeedsPowder } from 'utils/powderComplex';
import { percentageScanned, sum as scanSum } from 'utils/scanningUtils';
import { sortInSpecificOrder } from 'utils/sortInDisplayOrder';
import { variantIsSupplementJar } from 'utils/variants';

import { shouldShowAccessoryGwp, shouldShowBrushConsolidation } from 'dux/featureFlags/selectors';
import {
  getBufferScannerSetting,
  getCategory,
  getSelectedPrepackStation,
  getViewMode,
} from 'dux/settings/selectors';

import {
  getBoxDetail,
  getBoxItems,
  getNeedBuffer,
  getOrderItemByPubkey,
  getOrderItemsFromBox,
  getProdItems,
  getProductionAction,
  getProductionDetails,
  getProductionLane,
  getProductionState,
  getScannedPubkeys,
  getScannedShippingLabel,
  getSelectedEndOfLaneScreen,
  getVariant,
  isBoxShippingScannable,
} from '../selectors';

// SCAN SETTINGS
export const isScannableItemsInBox = createSelector(
  getOrderItemsFromBox,
  find({ variant: { is_scannable: true } })
);

export const getRequireScanning = createSelector(
  getBufferScannerSetting,
  getNeedBuffer,
  getProductionAction,
  isScannableItemsInBox,
  (bufferScanner, needBuffer, productionAction, isScannableItems) =>
    includes(bufferScanner, ['partial', 'full', 'partialAndShippingLabelMandatory']) &&
    isScannableItems &&
    (Boolean(productionAction === 'packing' && !needBuffer) ||
      productionAction === 'buffered' ||
      productionAction === 'prepack')
);

export const isBackupScan = createSelector(getBufferScannerSetting, bufferScanner =>
  includes(bufferScanner, ['partial', 'partialAndShippingLabelMandatory'])
);

export const isShippingLabelMandatory = createSelector(
  getBufferScannerSetting,
  isBoxShippingScannable,
  (bufferScannerSetting, isShippingLabelScannable) => {
    if (!isShippingLabelScannable) return false;
    return includes(bufferScannerSetting, ['full', 'partialAndShippingLabelMandatory']);
  }
);

// SCANNED LANE
export const getFirstScannedProductionItemLane = createSelector(
  [getBoxDetail, getProdItems, getScannedPubkeys],
  (_, prodItems, scannedPubkeys) =>
    flow(
      find(pi => pi?.pubkey === first(scannedPubkeys)),
      get('production_lane')
    )(prodItems)
);

// ITEMS DISPLAYED
export const getAccessoriesInBox = createSelector(getBoxItems, boxItems =>
  filter({ variant: { is_bulky: true } }, boxItems)
);

export const getItemsAreProducible = createSelector(getBoxItems, items =>
  filter(item => item.variant.is_producible && item.quantity > 0, items)
);

export const getNonProducibleItemsOnAutomationLane = createSelector(
  getItemsAreProducible,
  getScannedPubkeys,
  getProdItems,
  (producibleItems, scannedPubkeys, prodItems) =>
    filter(orderItem => {
      const firstPubkeyScanned = scannedPubkeys[0]; // We suppose that is a pubkey producible on automation
      const laneOfFirstPubkeyScanned = flow(
        find({ pubkey: firstPubkeyScanned }),
        get('production_lane')
      )(prodItems);

      const isProducibleOnlane = Boolean(
        find({ production_lane: laneOfFirstPubkeyScanned }, orderItem.production_items)
      );

      // items containing powder 'radiance_bl' end up on EOLC, non producible on EOLA/EOLB
      return isProducibleOnlane && !productNeedsPowder(orderItem) ? null : orderItem;
    }, producibleItems)
);

export const getProducedOrPrepackedOrBufferingItems = createSelector(
  [getProdItems, getOrderItemsFromBox, getScannedPubkeys],
  (prodItems, orderItemsFromBox, scannedPubkeys) => {
    const firstPubkeyScanned = scannedPubkeys[0];
    const laneOfFirstPubkeyScanned = flow(
      find({ pubkey: firstPubkeyScanned }),
      get('production_lane')
    )(prodItems);

    return filter(
      i =>
        includes(laneOfFirstPubkeyScanned, i.production_lane) &&
        (i.variant.is_formula || (shouldShowBrushConsolidation && brushChecker(i.variant.slug))) &&
        i.quantity > 0 &&
        find(
          productionItems =>
            includes(productionItems.status, ['produced', 'prepacked', 'buffering']),
          i.production_items
        ),
      orderItemsFromBox
    );
  }
);

export const getEndOfLaneItems = createSelector(
  [getProdItems, getOrderItemsFromBox, getScannedPubkeys, getSelectedEndOfLaneScreen],
  (prodItems, orderItemsFromBox, scannedPubkeys, selectedEndOfLaneScreen) => {
    const firstPubkeyScanned = scannedPubkeys[0];
    const laneOfFirstPubkeyScanned = flow(
      find({ pubkey: firstPubkeyScanned }),
      get('production_lane')
    )(prodItems);

    // items containing powder 'radiance_bl' end up on EOLC, non producible on EOLA/EOLB
    const handlePowderProduct = item => {
      if (!productNeedsPowder(item)) return true;
      if (selectedEndOfLaneScreen === 'eolc') {
        return productNeedsPowder(item);
      }
      return !productNeedsPowder(item);
    };

    return flow(
      filter(i => includes(laneOfFirstPubkeyScanned, i?.production_lane)),
      map(i => {
        const statuses = map('status', i.production_items);
        return {
          ...i,
          statuses,
        };
      }),
      filter(
        item =>
          item?.variant?.is_formula &&
          !isEmpty(intersection(item?.statuses, ['pending', 'producing', 'produced'])) &&
          handlePowderProduct(item) &&
          item.quantity > 0
      )
    )(orderItemsFromBox);
  }
);

export const getEndOfLaneComplexItemScanned = createSelector(
  [getProdItems, getOrderItemsFromBox, getScannedPubkeys],
  (prodItems, orderItemsFromBox, scannedPubkeys) => {
    const firstPubkeyScanned = scannedPubkeys[0];

    return find(
      item =>
        item?.variant?.is_formula &&
        item?.quantity > 0 &&
        find(pi => pi.pubkey === firstPubkeyScanned, item?.production_items),
      orderItemsFromBox
    );
  }
);

export const getEndOfLaneProdItemsStatus = createSelector(
  [getProdItems, getScannedPubkeys],
  (prodItems, scannedPubkeys) =>
    get(
      'status',
      find(pi => pi.pubkey === scannedPubkeys[0], prodItems) // For skincare there will always be one scanned pubkey
    )
);

export const getEndOfLaneBufferSpace = createSelector(
  [getEndOfLaneComplexItemScanned, getProducedOrPrepackedOrBufferingItems, getCategory],
  (endOfLaneComplexItemScanned, producedOrPrepackedOrBufferingItems) => {
    return producedOrPrepackedOrBufferingItems;
  }
);

export const allProdItemsBuffered = createSelector(
  getProdItems,
  prodItems => prodItems && filter({ status: 'buffered' }, prodItems).length === prodItems.length
);

export const getEndOfLaneItemsDefect = createSelector(
  [getProdItems, getOrderItemsFromBox, getScannedPubkeys],
  (prodItems, orderItemsFromBox, scannedPubkeys) => {
    const firstPubkeyScanned = scannedPubkeys[0];
    const proditem = find({ pubkey: firstPubkeyScanned })(prodItems);
    const laneOfFirstPubkeyScanned = flow(
      find({ pubkey: firstPubkeyScanned }),
      get('production_lane')
    )(prodItems);
    const filteredItems = filter(
      i => includes(laneOfFirstPubkeyScanned, i.production_lane),
      orderItemsFromBox
    );
    return filter(
      item =>
        item.variant.is_formula &&
        item.quantity > 0 &&
        find(pi => pi?.pubkey === proditem?.pubkey, item.production_items),
      filteredItems
    );
  }
);

export const handleAccessoriesQuantities = items => {
  const consolidatedItems = [];
  forEach(item => {
    const foundIndex = findIndex(['variant.pubkey', item.variant.pubkey], consolidatedItems);
    if (foundIndex >= 0 && consolidatedItems[foundIndex]?.variant?.is_bulky) {
      const updatedQuantity = assign(consolidatedItems[foundIndex], {
        quantity: consolidatedItems[foundIndex].quantity + item.quantity,
        quantity_in_box: consolidatedItems[foundIndex].quantity_in_box + item.quantity_in_box,
      });
      consolidatedItems[foundIndex] = updatedQuantity;
    } else consolidatedItems.push(item);
  }, items);
  return consolidatedItems;
};

export const getPrepackItems = createSelector(
  [getOrderItemsFromBox, getSelectedPrepackStation, shouldShowAccessoryGwp],
  (orderItems, prepackStation, showAccessoryGwp) => {
    const prepackLabProduct = flow(split('_'), first)(prepackStation);
    if (prepackLabProduct === 'supplements') {
      // Multilane prepack supps
      return filter(
        item =>
          (item.variant.is_formula ||
            item.variant.is_bulky ||
            (shouldShowBrushConsolidation && brushChecker(item.variant.slug))) &&
          item.quantity > 0 &&
          includes('supplement', item.variant.slug)
      )(orderItems);
    }
    const filteredOrderItems = filter(item => {
      return (
        (item.variant.is_formula ||
          item.variant.is_bulky ||
          (shouldShowBrushConsolidation && brushChecker(item.variant.slug))) &&
        item.quantity > 0
      );
    })(orderItems);
    if (!showAccessoryGwp) return filteredOrderItems;
    return handleAccessoriesQuantities(filteredOrderItems);
  }
);

export const getPrepackingItemsScanned = createSelector(
  [getProdItems, getOrderItemsFromBox, getScannedPubkeys],
  (prodItems, orderItemsFromBox, scannedPubkeys) =>
    filter(
      orderItem =>
        (orderItem?.variant?.is_formula ||
          orderItem?.variant?.is_bulky ||
          (shouldShowBrushConsolidation && brushChecker(orderItem?.variant?.slug))) &&
        orderItem.quantity > 0 &&
        find(
          productionItems =>
            includes(productionItems.pubkey, scannedPubkeys) &&
            includes(productionItems.status, ['prepacking']),
          orderItem.production_items
        ),
      orderItemsFromBox
    )
);

export const getPrepackingProdItemsPubkeysScanned = createSelector(
  getPrepackingItemsScanned,
  prepackingItemsScanned =>
    flatMap(items => map(get('pubkey'), items.production_items), prepackingItemsScanned)
);

export const getItemsInLeftSide = createSelector(
  [getBoxDetail, getOrderItemsFromBox, shouldShowAccessoryGwp],
  (box, items, showAccessoryGwp) => {
    const leftProducts = packingDisplayedItems.left.products;

    const filterAndSortLeftSideItems = flow(
      filter(item => includes(get('variant.product.type', item), leftProducts)),
      sortInSpecificOrder(get('variant.product.type'), leftProducts)
    );

    if (showAccessoryGwp) {
      const consolidatedItems = handleAccessoriesQuantities(items);
      if (box?.contains_core_products) {
        return filterAndSortLeftSideItems(consolidatedItems);
      }
      return consolidatedItems;
    }
    if (box?.contains_core_products) {
      return filterAndSortLeftSideItems(items);
    }
    return items;
  }
);

const getOrderItemsFromBoxWithoutCollaterals = createSelector(
  getOrderItemsFromBox,
  filter(item => item.variant.product.type !== 'collateral')
);

export const getItemsInRightSide = createSelector(
  [getBoxDetail, getOrderItemsFromBoxWithoutCollaterals],
  (box, items) => {
    const rightProducts = packingDisplayedItems.right.products;

    const filterAndSortRightSideItems = flow(
      filter(item => includes(get('variant.product.type', item), rightProducts)),
      sortInSpecificOrder(get('variant.product.type'), rightProducts)
    );

    if (box?.contains_core_products) {
      return addDuplicateVariantToScan(filterAndSortRightSideItems(items));
    }
    return null;
  }
);

// SCANNED ITEMS QTY
export const getProducedItemQuantityScanned = createSelector(
  [getProductionState, getOrderItemByPubkey, shouldShowAccessoryGwp],
  (state, orderItem, showAccessoryGwp) => {
    let scannedProduced = 0;
    if (showAccessoryGwp && orderItem?.variant?.is_bulky) {
      forEach(pi => {
        if (includes(pi.pubkey, state.details.scannedPubkeys)) {
          scannedProduced += 1;
        }
      }, orderItem?.production_items);
    } else {
      find(pi => {
        if (includes(pi.pubkey, state.details.scannedPubkeys)) {
          scannedProduced += 1;
        }
      }, orderItem?.production_items);
    }
    return scannedProduced;
  }
);

export const getProdItemsPendingComplexQty = createSelector(
  getEndOfLaneComplexItemScanned,
  flow(
    get('production_items'),
    filter({ status: 'producing_step_2' }),
    size // null-safe way of getting length
  )
);

export const getQuantityInBoxForScannableItems = createSelector(
  getProductionDetails,
  getOrderItemByPubkey,
  ({ variantsScannedPubkey }, orderItemPubkey) =>
    get('quantity_in_box', find({ pubkey: orderItemPubkey.variant.pubkey }, variantsScannedPubkey))
);

export const getVariantQuantityScanned = createSelector(
  [getProductionDetails, getOrderItemByPubkey],
  ({ variantsScannedPubkey }, orderItem) => {
    const foundVariantScannedPubkey = find({ pubkey: orderItem.variant.pubkey })(
      variantsScannedPubkey
    );

    return get('scannedQuantity')(foundVariantScannedPubkey);
  }
);

export const getTotalVariantQuantityScanned = createSelector(
  getProductionDetails,
  ({ variantsScannedPubkey }) => flow(map(get('scannedQuantity')), sum)(variantsScannedPubkey)
);

export const getQuantityScanned = createSelector(
  getOrderItemByPubkey,
  getViewMode,
  getProducedItemQuantityScanned,
  getVariantQuantityScanned,
  (item, viewMode, producedItemQuantityScanned = 0, variantQuantityScanned = 0) => {
    if (viewMode !== 'packingstation') {
      if (variantIsSupplementJar(item.variant)) {
        // We want percentage for supplements only
        return percentageScanned(scanSum(producedItemQuantityScanned, variantQuantityScanned), 2);
      } // Else a simple sum
      return scanSum(producedItemQuantityScanned, variantQuantityScanned);
    }
    return scanSum(producedItemQuantityScanned, variantQuantityScanned);
  }
);

const getIsProducedProdItems = createSelector(
  getProductionLane,
  getOrderItemByPubkey,
  (selectedLane, orderItem) => {
    return (
      Boolean(includes(selectedLane, get('production_lane', orderItem))) &&
      Boolean(find(pi => pi.status !== 'pending', get('production_items', orderItem)))
    );
  }
);

const getOrderItemQuantity = createSelector(getOrderItemByPubkey, get('quantity'));
const getOrderItemProductionLane = createSelector(getOrderItemByPubkey, get('production_lane'));

const getVariantBoxSlot = createSelector(getVariant, get('box_slot'));

export const isProducibleAndBuffered = createSelector(
  [
    getProductionAction,
    getProductionLane,
    getIsProducedProdItems,
    allProdItemsBuffered,
    getOrderItemQuantity,
    getOrderItemProductionLane,
    getVariantBoxSlot,
  ],
  (
    action,
    lane,
    isProducedProdItems,
    prodItemsBuffered,
    itemQuantity,
    itemProductionLane,
    variantBoxSlot
  ) => {
    return (
      action === 'buffered' ||
      isProducedProdItems ||
      prodItemsBuffered ||
      (itemQuantity !== 0 && itemProductionLane === lane) ||
      variantBoxSlot === 0
    );
  }
);

export const getItemQuantityToProducedOrBuffered = createSelector(
  [getProductionState, getOrderItemByPubkey, getViewMode, getCategory],
  (_state, orderItem, viewMode) => {
    const producedItems = filter(pi => {
      if (viewMode === 'endoflanebufferspace') {
        return includes(pi.status, ['produced', 'prepacked', 'buffering']);
      }
      return includes(pi.status, ['producing', 'produced']);
    }, orderItem.production_items);

    return producedItems.length;
  }
);

export const getQuantityInBox = createSelector(getOrderItemByPubkey, get('quantity_in_box'));

export const getShippingLabelScannedQuantityPackingStation = createSelector(
  [isShippingLabelMandatory, getScannedShippingLabel],
  (shippingLabelMandatory, isScannedShippingLabel) => {
    if (!shippingLabelMandatory) return null;

    return isScannedShippingLabel ? 1 : 0;
  }
);

// SCANNED ITEM STATUS
export const getStatusOfLastScannedItem = createSelector(
  getScannedPubkeys,
  getProdItems,
  (scannedPubkeys, proditems) =>
    flow(
      find(pi => pi.pubkey === scannedPubkeys[scannedPubkeys.length - 1]),
      get('status')
    )(proditems)
);
